import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  Output,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationFacade } from '@core/facades/notification.facade';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-input-file',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-file.component.html',
  styleUrl: './input-file.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileComponent {
  @Output() uploadedFileEvent = new EventEmitter<FileList | null>();

  @Input() inputLabel!: string;
  @Input({ required: true }) inputId!: string;
  @Input() acceptedFileTypes: string[] = [];
  @Input() maxFileSize!: number; // in KB

  fileName!: string;
  fileSize!: string;

  isFileUploadedSubject = new BehaviorSubject<boolean>(false);
  isFileUploaded$ = this.isFileUploadedSubject.asObservable();

  toast = inject(NotificationFacade);
  change = inject(ChangeDetectorRef);

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (!target.files) {
      return;
    }

    let numberOfFiles = target.files.length;
    this.isFileUploadedSubject.next(true);
    if (numberOfFiles === 1) {
      this.fileName = target.files[0].name;
      this.fileSize = target.files[0].size / 1000 + 'KB';
    } else if (numberOfFiles > 1) {
      this.fileName = numberOfFiles + ' files uploaded';
    }

    // if no file types are specified, then accept all file types
    if (this.acceptedFileTypes.length === 0) {
      this.uploadedFileEvent.emit(target.files);
      return;
    }

    // if maxFileSize is not specified, then accept all file sizes
    if (this.maxFileSize === undefined) {
      this.uploadedFileEvent.emit(target.files);
      return;
    }

    // check file size
    if (target.files[0].size > this.maxFileSize * 1000) {
      setTimeout(() => {
        this.toast.error(
          `File size is too large. Maximum size is ${this.maxFileSize.toLocaleString('en')}KB`
        );
        this.deleteUploadedFile();
      }, 0);

      return;
    }

    // check file type
    const fileType = target.files[0].type;
    if (!this.acceptedFileTypes.includes(fileType)) {
      setTimeout(() => {
        this.toast.error(
          `File type is not supported. Only ${this.acceptedFileTypes.join(
            ', '
          )} files are supported`
        );

        this.deleteUploadedFile();
      }, 0);
      return;
    }

    this.uploadedFileEvent.emit(target.files);
  }

  deleteUploadedFile(): void {
    this.isFileUploadedSubject.next(false);
    this.uploadedFileEvent.emit(null);
  }
}
