<div class="input-file__wrapper flex flex-col gap-[0.62rem]">
  <!-- Upload the document -->
  @if (!(isFileUploaded$ | async)) {
    <div
      [ngClass]="{ hidden: (isFileUploaded$ | async) }"
      class="file-upload__wrapper"
    >
      <input
        type="file"
        name=""
        [id]="inputId"
        class="inputfile absolute z-[-1] h-[0.1px] w-[0.1px] overflow-hidden opacity-0"
        multiple
        (change)="onFileSelected($event)"
        [accept]="
          acceptedFileTypes.length ? acceptedFileTypes.join(', ') : undefined
        "
      />
      <label
        for
        [htmlFor]="inputId"
        class="document-upload-label inline-flex w-full cursor-pointer items-center gap-[0.62rem] rounded-xl border border-dashed border-mango-bisque px-[0.62rem]"
      >
        <div
          class="icon__wrapper flex h-[2.4375rem] w-[2.4375rem] shrink-0 items-center justify-center rounded-full bg-mango-papayaWhip"
        >
          <img
            src="assets/images/svgs/upload-icon--yellow.svg"
            alt="upload document icon"
          />
        </div>
        <span class="px-0 py-5 text-xs leading-6 text-mango"
          >Upload {{ inputLabel }}</span
        >
      </label>
    </div>
  }

  <!-- Delete the document -->
  @if (isFileUploaded$ | async) {
    <div
      [ngClass]="{ hidden: !(isFileUploaded$ | async) }"
      class="file-delete__wrapper inline-flex w-full items-center justify-between rounded-xl border border-dashed border-mango-bisque bg-mango-grayF9 px-[0.62rem]"
    >
      <!-- left -->
      <div class="inline-flex items-center gap-[0.62rem]">
        <div
          class="icon__wrapper flex h-[2.4375rem] w-[2.4375rem] flex-shrink-0 items-center justify-center rounded-full bg-mango-papayaWhip"
        >
          <img
            src="assets/images/svgs/document--yellow.svg"
            alt="upload document icon"
          />
        </div>
        <div class="py-5">
          <span class="file-name px-0 text-xs leading-6 text-mango">{{
            fileName
          }}</span>
        </div>
      </div>

      <!-- right -->
      <img
        (click)="deleteUploadedFile()"
        src="assets/images/svgs/x-close.svg"
        alt="Icon to Delete Uploaded Document"
        class="h-5 w-5 cursor-pointer"
      />
    </div>
  }
</div>
